<template>
  <div class="w-full h-auto pb-40 pt-20">
    <div class="text-2xl font-bold mb-5">{{ title }}</div>
<!--    <div class="grid items-center justify-items-center">-->
<!--      <div class="mb-10 w-5/12">{{ description }}</div>-->
<!--    </div>-->
    <div class="box">
      <svg
        class="justify-self-end self-center w-8"
        viewBox="0 0 220 220"
        width="200"
        height="146"
        @click="prev"
      >
        <path
          d="M0 73 L200 146 L170 73 L200 0 z"
          class="fill-current text-button hover:text-arrow-hover transition:colors duration-600 cursor-pointer"
        ></path>
      </svg>
      <VueSlickCarousel v-bind="settings" class="carousel" ref="carousel">
        <div v-for="(picture, index) in pictures" :key="index">
          <div class="grid items-center justify-items-center">
            <img :src="picture" class="w-60" draggable="false" />
          </div>
        </div>
      </VueSlickCarousel>
      <svg
        class="justify-self-start self-center w-8"
        viewBox="0 0 220 220"
        width="200"
        height="146"
        @click="next"
      >
        <path
          d="M0 146 L200 80 L0 0 L30 73 z"
          class="fill-current text-button hover:text-arrow-hover transition:colors duration-600 cursor-pointer"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      title: "页面展示",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text",
      pictures: this.getPictures(),
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
        autoplay: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        arrows: false,
      },
    };
  },
  methods: {
    getPictures() {
      let pictures = [];
      let imagesContext = require.context(
        "@/pictures/Screenshot",
        false,
        /^\.\//
      );
      imagesContext.keys().forEach((image) => {
        pictures.push(imagesContext(image));
      });
      return pictures;
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style scoped>
.carousel {
  grid-column-start: 2;
}

.box {
  display: grid;
  grid-template-columns: 20% 60% 20%;
}
</style>