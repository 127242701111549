<template>
    <div class="bg-gradient-to-r from-left to-right w-full h-auto pt-5 pb-5 bottomText">
        <div>©Copyright {{year}} {{company}}</div>
        <div style="cursor: pointer" @click="gotoUrls(beian_url)" :href="beian_url" target="_blank">{{beian}}</div>
        <div style="cursor: pointer" :href="gotoUrls(privacy_policy_url)" target="_blank">隐私政策权</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            company:'宾团投资控股（海南）有限公司',
            year: '2023',
            beian: '琼ICP备2023003740',
            beian_url: 'https://beian.miit.gov.cn/',
            privacy_policy_url: './static/privacy_policy.html',
        }
    },
  methods:{
      gotoUrls(url){
        window.open(url)
      }
  }
}
</script>
<style>
.bottomText {
  font-weight: 400;
  color: #FFFFFF !important;
  line-height: 20px;
}
</style>