var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"grid items-center justify-items-center"},[_c('div',{class:[
        'fixed',
        'z-50',
        'top-0',
        'w-full',
        { 'bg-gradient-to-r': _vm.fixed },
        { 'from-left': _vm.fixed },
        { 'to-right': _vm.fixed },
        { 'border-b': _vm.fixed },
        { 'border-white': _vm.fixed },
        { 'h-16': _vm.fixed },
        { 'h-12': !_vm.fixed },
        'transition-colors',
        'duration-600',
        'transition-height',
      ]},[_c('div',{class:[
          'grid',
          'grid-cols-12',
          'items-center',
          'justify-items-center',
          { 'mt-6': !_vm.fixed },
          { 'mt-4': _vm.fixed },
          'duration-600',
          'transition-spacing',
        ]},[_c('div',{staticClass:"col-span-6 h-8",staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticStyle:{"width":"48px"},attrs:{"src":_vm.logo}}),_c('div',{staticClass:"appTitle"},[_vm._v("宾团APP")])]),_vm._l((_vm.names),function(name,index){return _c('div',{key:index,class:[
            'font-bold',
            'text-base',
            'cursor-pointer',
            'hover:text-black',
            'text-white',
          ],style:({ color: index == _vm.active ? 'black' : '' }),on:{"click":function($event){return _vm.scrollTo(index)}}},[_vm._v(" "+_vm._s(name)+" ")])})],2)])]),_c('Home',{attrs:{"name":"anchor"}}),_c('About',{attrs:{"name":"anchor"}}),_c('Feature',{attrs:{"active":_vm.active,"name":"anchor"}}),_c('Screenshot',{attrs:{"name":"anchor"}}),_c('Bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }