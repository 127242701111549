<template>
  <div class="bg-gradient-to-r from-left to-right w-full h-auto">
    <div class="bg-home-shape bg-no-repeat bg-cover">
      <div class="grid grid-cols-2">
        <div class="self-center ml-40 mr-20 text-left">
          <div class="self-end text-4xl mb-5 font-bold text-text">
            <div style="display: flex;flex-direction: row;align-items: center">
              <img :src="homeTitleIcon" style="width: 64px;margin-bottom: 10px">
              <img :src="homeTitle" style="width: 430px;margin-bottom: 10px;margin-left: 33px">
            </div>
          </div>
          <div class="text-xl mt-5 mb-16 text-text titleSub">{{ text }}</div>
          <div class="grid grid-cols-3 items-center justify-items-center">
            <div
              @click="download('Android')"
              class="
                py-3
                px-6
                rounded-full
                bg-white
                w-40
                text-center
                font-bold
                text-xl
                hover:bg-opacity-0
                border
                text-button
                transition-colors
                duration-600
                border-white
                hover:text-white
                cursor-pointer
              "
            >
              {{ Android }}
            </div>
            <div
              @click="download('IOS')"
              class="
                py-3
                px-6
                rounded-full
                w-40
                text-center
                font-bold
                text-xl
                border
                transition-colors
                duration-600
                border-white
                text-white
                bg-button
                hover:text-button hover:bg-white
                cursor-pointer
              "
            >
              {{ IOS }}
            </div>
          </div>
        </div>
        <div><img :src="picture" class="mt-16 mb-20" /></div>
      </div>
    </div>
    <a-modal v-model="visible" :footer="null" @cancel="close" :width="300" >
      <div class="grid justify-items-center items-center" style="height:250px">
        <div id="root" ref="qrCodeUrl"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      title: "宾团APP",
      text: "退役军人就业创业，宾团扶农助农",
      picture: require("@/pictures/Home/home-phone.png"),
      homeTitle: require("@/pictures/Home/编组_3@2x.png"),
      homeTitleIcon: require("@/pictures/Home/原logo_png@2x.png"),
      Android: "Android",
      IOS: "IOS",
      urlOfAndroid: "https://www.baidu.com",
      urlOfIOS: "https://www.jd.com",
      visible: false,
    };
  },
  methods: {
    download(type) {
      this.visible = true;
      if(type == 'Android'){
        this.creatQrCode(this.urlOfAndroid);
      }else if(type == 'IOS'){
        this.creatQrCode(this.urlOfIOS);
      }
    },
    close() {
      this.visible = false;
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    creatQrCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
  },
};
</script>

<style scoped>
@import "../css/tailwindcss.css";
.titleSub{
  width: 473px;
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  letter-spacing: 1px;
}
</style>