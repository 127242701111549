<template>
  <div class="bg-gradient-to-r from-left to-right w-full h-auto">
    <div class="bg-feature-shape bg-no-repeat bg-cover">
      <div class="grid grid-cols-2 text-left items-center">
        <img :src="picture" class="justify-self-end mr-17 mt-32 mv-12" />
        <div class="justify-self-start">
          <div class="text-3xl font-bold w-3/5 mb-10 text-text">
            <div class="featureTitle">
              {{ title }}
            </div>
          </div>
          <div
            v-for="(feature, index) in list"
            :key="index"
            style="width: 400px"
            :class="[
              'font-bold',
              'mb-4',
              'w-9/12',
              'text-text',
              'text-base',
              'duration-2400',
              { 'mt-6': active != 2 },
              'transition-spacing',
            ]"
          >
            <div
              :class="[
                { 'opacity-50': active != 2 },
                'transition-opacity',

                'duration-1000',
              ]"
            >
              <div class="featureSubTitle">
                > {{ feature }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      picture: require("@/pictures/Feature/feature-phone.png"),
      title: "软件优势",
      list: [
        "高性价比团购电商平台。",
        "帮扶军人创业就业。",
        "专人送货上门，服务优质。",
        "为军人谋福利，景区酒店享折扣。"
      ],
    };
  },
};
</script>
<style>
.featureTitle{
  font-size: 32px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
  color: #FFFFFF;
  line-height: 45px;
  letter-spacing: 1px;
}
.featureSubTitle{
  font-size: 16px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
  color: #FFFFFF;
  line-height: 22px;
  margin-bottom: 20px;
}
</style>