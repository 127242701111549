<template>
  <div class="w-full h-auto mb-20">
    <div class="h-20"></div>
    <div class="text-xl font-bold aboutTitle">{{ title }}</div>
<!--    <div class="grid">-->
<!--      <div class="text-base mt-5 w-5/12 justify-self-center self-center">-->
<!--        {{ titleDescription }}-->
<!--      </div>-->
<!--    </div>-->
    <div class="grid justify-items-center items-center">
      <div
        class="
          grid grid-cols-3
          justify-items-center
          items-center
          mt-10
          w-3/4
          gap-x-3
        "
      >
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="leftPicture"
            style="width: 235px"
            class="w-20 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold aboutOneTitle">{{ leftTitle }}</div>
            <div class="text-base mt-3 aboutOneSubTitle">{{ leftDescription }}</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="middlePicture"
            style="width: 235px"
            class="w-20 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold aboutOneTitle">{{ middleTitle }}</div>
            <div class="text-base mt-3 aboutOneSubTitle">{{ middleDescription }}</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center">
          <img
            :src="rightPicture"
            style="width: 235px"
            class="w-20 hover:scale-125 transition ease-out duration-700"
          />
          <div>
            <div class="text-lg font-bold aboutOneTitle">{{ rightTitle }}</div>
            <div class="text-base mt-3 aboutOneSubTitle">{{ rightDescription }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "关于App",
      titleDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text",

      leftPicture: require("@/pictures/About/About-left.png"),
      leftTitle: "实惠团购服务",
      leftDescription:
        "高性价比电商团购",

      middlePicture: require("@/pictures/About/About-middle.png"),
      middleTitle: "军人创业就业",
      middleDescription:
        "共建退役军人创业生态圈",

      rightPicture: require("@/pictures/About/About-right.png"),
      rightTitle: "宾团助农扶农",
      rightDescription:
        "携手农民致富，帮扶乡村振兴",
    };
  },
};
</script>
<style>
.aboutTitle{
  font-size: 32px !important;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
  color: #333333;
  line-height: 45px;
  margin-bottom: 20px;
}
.aboutOneTitle{
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.aboutOneSubTitle{
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 22px;
}
</style>